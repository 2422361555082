import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaHandshake, FaBullhorn, FaStar, FaShieldAlt, FaRegLightbulb, FaUserFriends } from 'react-icons/fa';
import { motion } from 'framer-motion';
import '../styles.css';

const valuesData = [
  {
    icon: <FaHandshake size={50} />,
    title: 'Confiabilidade',
    description: 'Atuamos com integridade e transparência, construindo relações de confiança duradouras com nossos clientes.'
  },
  {
    icon: <FaBullhorn size={50} />,
    title: 'Transparência',
    description: 'Mantemos uma comunicação clara e aberta, assegurando que todas as transações e processos sejam compreensíveis e justos.'
  },
  {
    icon: <FaStar size={50} />,
    title: 'Excelência',
    description: 'Buscamos continuamente a excelência em nossos serviços, oferecendo soluções personalizadas que atendem às necessidades individuais de cada cliente.'
  },
  {
    icon: <FaShieldAlt size={50} />,
    title: 'Segurança',
    description: 'Priorizamos a segurança em todas as nossas operações, garantindo que o patrimônio de nossos clientes esteja sempre protegido.'
  },
  {
    icon: <FaRegLightbulb size={50} />,
    title: 'Eficiência',
    description: 'Entregamos resultados rápidos e precisos, otimizando processos para maximizar a rentabilidade e satisfação de nossos clientes.'
  },
  {
    icon: <FaUserFriends size={50} />,
    title: 'Foco no Cliente',
    description: 'Colocamos as necessidades e expectativas de nossos clientes em primeiro lugar, trabalhando para superar suas expectativas e conquistar sua fidelidade.'
  }
];

const Values = () => {
  return (
    <Container id="values" className="values-section">
      <h2 className="text-center">Valores</h2>
      <Row>
        {valuesData.map((value, index) => (
          <Col md={4} key={index} className="mb-4">
            <motion.div whileHover={{ scale: 1.05 }}>
              <Card className="value-card text-center">
                <Card.Body>
                  <div className="value-icon">{value.icon}</div>
                  <Card.Title>{value.title}</Card.Title>
                  <Card.Text>{value.description}</Card.Text>
                </Card.Body>
              </Card>
            </motion.div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Values;
