import React from 'react';
import { Container, Button } from 'react-bootstrap';
import '../styles.css';

const Contact = () => {
    const whatsappUrl = "https://wa.me/5511999997035?text=Olá, gostaria de saber mais sobre os serviços da CJR Gestão Financeira Automotiva."; // Substitua pelo seu número de WhatsApp

    return (
        <div className="contact-section">
            <Container>
                <div className="contact-box text-center">
                    <h2>Está pronto para começar seu negócio?</h2>
                    
                    <Button
                        variant="primary"
                        size="lg"
                        onClick={() => window.open(whatsappUrl, '_blank')}
                    >
                        Enviar mensagem
                    </Button>
                </div>
            </Container>
        </div>
    );
};

export default Contact;
