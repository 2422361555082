import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Mission from './components/Mission';
import Values from './components/Values';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './styles.css';

const App = () => {
  return (
    <div className="app">
      <Header />
      <div id="home">
        <HeroSection />
      </div>
      <div id="about">
        <Mission />
      </div>
      <div id="values">
        <Values />
      </div>
      <div id="about-us">
        <AboutUs />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default App;
