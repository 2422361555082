import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles.css';

const Footer = () => {
    const whatsappUrl = "https://wa.me/5511999997035?text=Olá, gostaria de saber mais sobre os serviços da CJR Gestão Financeira Automotiva."; // Substitua pelo seu número de WhatsApp

    return (
        <footer className="footer">
            <Container>
                <Row>
                    <Col md={4}>
                        <h5>CJR</h5>
                        <p>Consultoria<br />São Paulo, SP</p>
                    </Col>
                    <Col md={4}>
                        <h5>Links</h5>
                        <ul>
                            <li><a href="#about">Sobre nós</a></li>
                            <li><a href={whatsappUrl} target="_blank" rel="noopener noreferrer">Contato</a></li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        <h5>Contato</h5>
                        <p>Email: cjrgestaofinanceira@gmail.com</p>
                        <p>Telefone: (11) 99999-7035</p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <p>© 2024 CJR Gestão Financeira. Todos os direitos reservados.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
