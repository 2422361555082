import React from 'react';
import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import '../styles.css';

const AboutUs = () => {
  return (
    <div className="about-us-section">
      <Container>
        <motion.div
          className="about-us-box"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <h2>Quem Somos</h2>
          <p>
            Sob a liderança do nosso sócio proprietário, Lauro Vieira De Campos Junior, economista com pós-graduação em Economia Internacional pela Universidade do Porto em Portugal, a CJR se especializou em oferecer serviços de compra e venda de bens móveis de maneira segura, confiável e rentável. A expertise e a visão estratégica de Lauro têm sido fundamentais para o desenvolvimento e consolidação da nossa empresa no mercado.
          </p>
        </motion.div>
      </Container>
    </div>
  );
};

export default AboutUs;
