import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';
import '../styles.css';

const Mission = () => {
    const whatsappUrl = "https://wa.me/5511999997035?text=Olá, gostaria de saber mais sobre os serviços da CJR Gestão Financeira Automotiva."; // Substitua pelo seu número de WhatsApp

    return (
        <div className="mission-section">
            <Container>
                <motion.div
                    className="mission-box"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    <h2>Missão</h2>
                    <p>
                        Nossa missão é fornecer soluções de excelência na gestão e intermediação de bens móveis, oferecendo serviços de compra e venda de maneira segura, confiável e rentável. Comprometemo-nos a atender às necessidades específicas de nossos clientes, garantindo sua satisfação e confiança através de resultados precisos e eficientes.
                    </p>
                    <Button
                        variant="success"
                        size="lg"
                        href={whatsappUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Entre em Contato
                    </Button>
                </motion.div>
            </Container>
        </div>
    );
};

export default Mission;
