import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import heroImage from '../assets/images/file.jpg';
import '../styles.css';

const HeroSection = () => {
  return (
    <div id="home" className="hero-section">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
            >
              <h1>Bem-vindo à CJR Gestão Financeira Patrimonial de Bens Móveis</h1>
              <p>
                Desde fevereiro de 1998, a CJR se dedica a fornecer soluções financeiras de excelência na área de gestão e intermediação de bens móveis. Nossa trajetória de mais de duas décadas é marcada por uma atuação pautada na confiabilidade, transparência e sucesso, valores que nos permitiram conquistar uma extensa e fiel carteira de clientes.
              </p>
            </motion.div>
          </Col>
          <Col md={6}>
            <motion.div
              className="profile-image-container"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
            >
              <img src={heroImage} alt="Profile" className="profile-image" />
            </motion.div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroSection;
