import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import '../styles.css';

const Header = () => {
    const whatsappUrl = "https://wa.me/5511999997035?text=Olá, gostaria de saber mais sobre os serviços da CJR Gestão Financeira."; // Substitua pelo seu número de WhatsApp

    return (
        <Navbar fixed="top" expand="lg" className="navbar-custom">
            <Container>
                <Navbar.Brand href="#home">CJR GESTÃO FINANCEIRA AUTOMOTIVA</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="#about">Sobre nós</Nav.Link>
                        <Nav.Link href={whatsappUrl} target="_blank" rel="noopener noreferrer">Contato</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
